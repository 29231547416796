.contacts iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.contacts i{
  color: #1eb2a6 !important;
}

.left{
  background-image: url(/public/images/blog/contact.jpg);
  background-color: rgb(27, 25, 25);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
  /* padding: 20px; */
}
.contacts .right {
  padding: 50px;
}
.contacts h1 {
  font-size: 30px;
  font-weight: 400;
}
.contacts p {
  color: grey;
}
.contacts .items {
  margin: 30px 0;
}
.contacts .box p {
  font-size: 17px;
  margin-top: 10px;
}
.contacts textarea,
.contacts input {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
  border: 1px solid grey;
}
form .flexSB input:nth-child(1) {
  margin-right: 10px;
}
.contacts h3 {
  margin-top: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.contacts span {
  color: #1eb2a6;
  font-weight: 600;
  font-size: 15px;
  word-spacing: 5px;
}
@media screen and (max-width: 768px) {
  .contacts .container {
    flex-direction: column;
  }
  .contacts iframe {
    height: 50vh;
  }
  .contacts .row {
    width: 100%;
  }
}


@media screen and (min-width: 280px) and (max-width: 420px) {
  .contacts iframe {
    height: 30vh;
  }

  .left{
      /* padding: 20px; */

  }

  .left > h1{
    font-size: 40px;
  }
}
