/*--------head------------*/
.header-home{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000; /* Adjust z-index as needed */
  transition: background-color 0.9s ease; /* Add transition effect for background color */

}


.r-container{
overflow: hidden;
}

.scrolled {
  background-color: #cfbaba;
}


.header-home.white-background {
  background-color: #1eb2a6; /* Change background color when white-background class is applied */
}

.head {
  padding: 20px 0;
  color: #fff;
}

.logo {
  width: 400px;
}

.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.head .icon {
  margin-left: 10px;
}
/*--------head------------*/
/*--------header------------*/
header {
  /* background-color: rgba(255, 255, 255, 0.2); */
  margin: 0 60px;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #1eb2a6;

}
.start {
  display: none;
}
.toggle {
  display: none;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

}

.fab.fa-facebook-f.icon::after{
  color: transparent !important;

}

.logo >span{
  font-size: small;
  font-weight: 500;

}


/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    background-color: #1eb2a6;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #1eb2a6;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 0;
    top: -20px;
  }
  .mobile-nav {
    position: fixed;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #000000;
  }

  .fa-times{
    color: #000000;
  }
}

/* .social_media_link{
  background-color: #1eb2a6;
} */


/* For small devices */
@media screen and (min-width: 150px) and (max-width: 767px){
  
 .logo h1{
  font-size: 25px;
 }

  .toggle{
    position: fixed;
    top:-15px;
  }

  .social {
    display: none;
    
  }

  .logo >span{
    font-size: small;
    font-weight: 500;
  }
  #heading >h1{
    font-size: 01.3rem;
  }

}