.v-container .image-container{
    border:8px solid rgb(232, 232, 232/93%);
}

.v-container>div{
    flex: 1;
}

.v-right{
    gap: .5rem;
}

.accordion{
    margin-top: 2rem;
    border: none;
}

.accordionItem{
    background: white;
    border: .8px solid rgba(128,128,128,.143);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
}

.accordionItem.expanded{
    box-shadow: var(--shadow);
    border-radius: 6px;
}

.accordionButton{
    background: white;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;

}

.icon{
    padding:10px;
    background: #eeeeff;
    border-radius: 5px;

}

.icon svg{
fill:#1eb2a6;
}

.accordionButton .primaryText{
    font-size: 1.1rem;
}