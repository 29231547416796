.hero {
  background-image: url("../../../public/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  /* padding-right: 50px; */
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}

.button-explore{

cursor: pointer;
}

.home_get_started{
  color: #fff;
    text-decoration: none;
}

.home_get_explore{
  color: #1eb2a6;
    text-decoration: none;
}

.button-explore:hover{
color: #fff;
background-color: #1eb2a6;
}

/* @media screen and  (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }

  
} */



@media screen and (min-width: 150px) and (max-width: 768px) {
  .hero #heading h3 {
    color: #fff;
    margin-top: 10px;
}

  .hero{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-right: 0%;
}

  .best_heading{
  margin-top: 70%;
  font-size: 0.8rem;
}

.row_header{
  padding-top: 200px;
  color: black;
  /* margin-bottom: 2rem; */
}


.row_header2{
  color: black;
  margin-bottom: 5rem;
  font-weight: 500;
}
.button{
  display: flex;
  justify-content: space-evenly;
}

.grab_mobile{
  background-color: #1eb2a6;
  font-size: 0.8rem;
  padding: 10px;
  border-radius: 12px;
  align-items: center;
  text-align: center;
}

.primary-btn .button-explore{
  font-size: 0.9rem;
  border-radius: 15px;
  white-space: nowrap;

}

.homeAbout{
  margin-top: 10px;
}

/* .row {
  margin-top: 150px;
} */



}


