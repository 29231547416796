.testimonal .items {
  padding: 30px;
  transition: 0.5s;
  margin-bottom: 30px;
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1eb2a6;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1eb2a6;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1eb2a6;
}


@media screen and (min-width: 280px) and (max-width: 420px) {
  .testimonal {
    padding-top: 0;
  }

  .testimonal p {
    margin-top: 20px;
    color: grey;
    transition: 0.5s;
    font-size: 15px;
}

.testimonal .name {
  margin: 25px 0 25px 25px;
}

.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1eb2a6;
  color: #fff;
  width: 30px;
  height: 30px;
}

.testimonal .name h2 {
  font-size: 17px;
  margin-bottom: 5px;
}

  .testimonal img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
}