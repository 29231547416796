.apply-head{
    background-color: rgba(153,188,194,255);
    background-size: cover;
  /* background-attachment: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 13%;
  padding-right: 50px;
  display: flex;
  color: #fff;
  justify-content: center;
  text-align: center;
  margin: 0 0 0px 0;
}

.apply{
    background-color: rgba(153,188,194,255);
    padding: 20px 0 0 0;
    color: rgb(84, 83, 83);
    display: flex;
    flex-direction: column;
    
}

.apply-title{
    font-family: "Cursive";
    text-align: center;
    margin: 10%;
    color: #fff;
    font-size: clamp(1.5rem, 3vw, 4rem);
    margin-bottom:200px;
}

.scroll{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 0.8rem;
    margin: 150px 0 100px 0;
}

.scroll:hover{
    color: #292828;
}

.whyUs{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: #878484;
   background-image: url("/public/images/svg/plansback.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
   padding: 30px;
   
}

.whyus-title{
color: #ffffff;
margin-bottom: 2%;
font-size: 40px;
font-weight: 800;

}

.whyus-points{
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 10px;
    justify-content: center;
   align-items: center;
   
}

.whyus-points > p{
    background-color: #fff;
    border-radius: 1rem;
    font-size: .8rem;
    margin: 20px;
    width: 300px;
    height: fit-content;
  display: inline-block;
  border: 1px solid rgb(255, 253, 253);
  transform: skewX(-10deg);
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 10px 10px 5px #292828;

}

.lineBetween{
   width: 1px;
   height: 300px; 
   display: inline-block;
}

.selectionProcess{
    background-color: #fff;
    padding: 120px 50px;
    display: grid;
    grid-template-columns: 44% 2% 44%;
    justify-content: center;
    align-items: center;
}

.firstColumn{
    display: flex;
    flex-direction: column;
    color: #1eb2a6;
    text-align: center;
    /* font-size: 0.6rem; */
}

.firstColumn-contents{
    padding: 30px;
    font-size: 0.8rem;
    line-height: 17px;
}

.firstColumn-contents-two{
    padding: 30px;
    font-size: 0.8rem;
    line-height: 17px;
    padding-top: 7px;
}


.SecondColumn-header{
    padding: 10px 30px;
    color: #1eb2a6;
}

.SecondColumn-contents{
    padding: 30px;
    font-size: 0.8rem;
    line-height: 17px;
    padding-top: 0px;
}

.SecondColumn-contents-two{
    padding: 30px;
    font-size: 0.8rem;
    line-height: 17px;
    padding-top: 7px;
    
}

.Disclaimer-group{
    background-color: #fff;
    height: 500px;
    background-image: url(/public/images/students/disclaimer.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
    display: grid;
    grid-template-columns: repeat(1, 60%);
    align-items: center;
    justify-content: center;
}

.Disclaimers{
    background-color: #cfbaba;
    padding: 40px;
    border-radius: 13px;
}

.scroll >svg{
    margin-left: 15px;
}

.Disclaimers >p{
    font-size: medium;
    line-height: 25px;
    color: #5b5757;
}
.disclaimers{
    color: #671a1a;
}

.register-space{
    display: flex;
    flex-direction: row;
    align-items: center;
   justify-content:center;
   text-decoration: none;
   padding: 20px;
   box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  color: #1eb2a6;
  background-color: #fff;
  border-radius: 10px;
  font-weight: bolder;
   }

   .register-space:hover{
    color: #fff;
    background-color: #1eb2a6;
    border-radius: 10px;
   }

.sub-buttom{
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .whyus-points{
        display: flex;
        flex-wrap: wrap;
    }
    
  }

  @media screen and (min-width: 150px) and (max-width: 420px) {
    .selectionProcess {
        background-color: #fff;
        padding: 90px 10px;
        display: flex;
        flex-direction: column;
    }
    .firstColumn{
        margin-left: 30px;
    }

    .Disclaimer-group {
        display: flex;
        background-image: none;
    }

    .apply-title{
        margin: 60% 0;
    }

    .whyus-title{
        font-size: 30px;
        text-align: center;
    }

    .lineBetween{
        display: none;
    }

    .Disclaimers >P{
        font-size: small;
    }

    .firstColumn {
        margin-left: 0;
    }
  }