
.coursesCard {
  padding: 50px 0;
}

.cut-off{
  /* 
  display: -webkit-box; 
  overflow: hidden;
-webkit-box-orient: vertical;
-webkit-line-clamp: var(--max-lines); */
  --max-lines:5;
  --line-height:1.4;
  display: flex;
  flex-direction: column;
  max-height: calc(var(--max-lines) * 1rem * var(--line-height));
  line-height: var(--line-height);
  overflow: hidden;
  position: relative;
}


.cut-off:has(+.outline-btn:not(:checked))::before{
  content: '';
  position: absolute;
  height: calc(1rem * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, white);
}


/**
display: -webkit-box; 

-webkit-box-orient: vertical;
*/
.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1eb2a6;
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard .rate {
  margin: 20px 0;
  color: #1eb2a6;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: #1eb2a6;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #1eb2a6;
}
/*-------------online---------*/
.online {
  text-align: center;
}
.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.5s;
}
.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #1eb2a6;
  border-radius: 5px;
}
.online .box .show {
  opacity: 0;
}
.online .box:hover {
  background-color: #1eb2a6;
  border-radius: 5px;
  cursor: pointer;
}
.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.online .box:hover h1 {
  color: #fff;
}

.outline-btn{
  appearance: none;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  padding: 17px 30px;
  background-color: #fff;
  color: #1eb2a6;
  font-weight: 600;
  border: 1px solid #1eb2a6;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
}

.outline-btn:hover {
  box-shadow: none;
}

.outline-btn::before{
  content: "LEARN MORE";
}

.outline-btn:checked::before{
  transition-delay: ease-in;
  content: "COLLASPE";
}

.outline-btn:checked::after{
  max-height: none;
}

.cut-off:has(+ .outline-btn:checked){
  max-height: none;
}

.opportunities{
  display: flex;
  justify-content: center;
  text-align: center;
}

.cut-off:has(+ .outline-btn:checked){
  max-height: none;
}

.doApper{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:9999;
  color:black;
  /* background-color:rgba(131, 127, 127, 0.8); */
  
}

.doDisappear{
  display: none;
}
.cover{
  width:100%;
max-width:552px;
background-color: white;
max-height:90%;
overflow:initial;
border-radius:5px;
position:relative;
display:flex;
flex-direction:column;
top:200px;
margin:0 auto;
}

.cover_sub >h1{
  text-align: center;
  margin: 30px;
  /* font-size: large; */
}

.cover_sub >ul{
  font-size: 1rem;
  margin: 0 20px 10px 20px;
}

.cover_sub{
  margin: 0 30px;
  display:flex;
flex-direction:column;
}


/*-------------online---------*/
@media screen and (max-width: 768px) {
  .items{
    display: flex;
    flex-direction: column;
  }

}


@media screen and (min-width: 150px) and (max-width: 420px) {
  .grid2{
  display: flex;
  flex-direction: column;
    margin-top: 0;
  }
  
  .question_title{
    font-size: 1.3rem;
  }

  .cut-off{
    font-size: 0.7rem;
    line-height: 0.7rem;
  }

  .opportunities{
    margin-top: 10px; 
  }

  .coursesCard {
    padding: 0px 0;
}

.coursesCard .items {
    background-color: transparent;
    padding: 30px 0;
    text-align: center;
}

.cut-off {
  font-size: 1rem;
  line-height: 1rem;

}

.cut-off >ul{
margin-top: 1rem;
}

.space_new{
  line-height: 2rem;
}
}
