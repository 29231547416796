/* Login section */
.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Login container */
.login-container {
  background-color: #1eb2a6;
  padding: 40px;
  border-radius: 8px;
  box-shadow: var(--shadow);
  text-align: center;
  color: #fff;
}

/* Login title */
.login-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

/* Login form */
.login-form {
  display: flex;
  flex-direction: column;
}

/* Form group (label + input) */
.form-group {
  margin-bottom: 20px;
}

/* Form input styles */
input[type='email'],
input[type='password'] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Login button */
.login-button {
  padding: 14px;
  background-color: var(--blue);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: var(--lightBlue);
}

/* Optional: Sign up link */
.signup-link {
  color: #fff;
  text-decoration: underline;
}

.signup-link:hover {
  color: var(--lightBlue);
}