.hero-wrapper{
    color: white;
    position: relative;
    padding-bottom: 2rem;
    /* background-color: black; */
}

.hero-container{
    justify-content: space-around;
    align-items: flex-end;
}

/* left side */
.hero-left{
    gap: 3rem;
}

.hero-title{
    position: relative;
    z-index: 1;
}

.hero-title>h1{
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}

.orange-circle{
    height:4rem;
    width: 4rem;
    background-color: #1eb2a6;
    border-radius: 999px;
    position:absolute;
    right:28%;
    top: -10%;
    z-index: -1;
}

.search-bar{
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}

.search-bar>input{
    border: none;
    outline: none;
    /* width: 100%; */
    font-size: 1.2rem;
    font-weight: 500;
}

.stats{
    width: 100%;
    justify-content: space-between;

}

.stat>:nth-child(1){
    font-size: 1.5rem;
    font-weight: 500;
}

.stat>:nth-child(1)>:last-child{
    color: #1eb2a6;
}


.stats>:nth-child(1)>:last-child,.stats>:nth-child(2)>:last-child,.stats>:nth-child(3)>:last-child{
    color: rgb(224, 169, 67);
}

/* right side */

.image-container{
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12);
}

.image-container > img{
    width: 100%;
    height: 100%;

}