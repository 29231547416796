@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

:root {
  --primary: #1f3e72;
  --secondary: rgba(255, 255, 255, 0.78);
  --black: #131110;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue: #4066ff;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
  
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;

}





.loader{
  text-align: center;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #d79237;
}


/*------------global-----------*/
.container {
  max-width: 85%;
  margin: auto;
}
.flexSB {
  display: flex;
  justify-content: space-between;
}
.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #1eb2a6;
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: #1eb2a6;
  color: #fff;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.row {
  width: 50%;

}

html {
  scroll-behavior: smooth;
}

/* .btn{
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: #fff;
  background-color: #1eb2a6;
  border-radius: 4px;
  transition: all 300ms ease-in;
}

.btn:hover{
  cursor: pointer;
  transition: scale(1.1);
} */



button {
  padding: 17px 30px;
  background-color: #fff;
  color: #1eb2a6;
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  transition: 0.5s;

}
button i {
  margin-left: 20px;
}
button:hover {
  box-shadow: none;
  /* background-color: #1eb2a6;
  color: #fff; */
}
.primary-btn {
  background-color: #1eb2a6;
  color: #fff;

}

.primary-btn:hover{
  background-color: #fff;
  color: #1eb2a6;
}




/*------------global-----------*/

/* "/public/images/ab.jpg */
.back {
  background-image: url(/public/images/svg/coverPage.jpg);
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}


.back h1 {
  font-size: 100px;
  font-weight: 400;
}
.back h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}

.margin {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}


.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

}


.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #1eb2a6;
  width: 100%;
  transition: 0.5s;
}
.outline-btn:hover {
  background-color: #1eb2a6;
  color: #fff;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  justify-content: center;
}
.padding {
  padding: 80px 0;
}
.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }



  #heading1 h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
  margin-top: 710px;
}
  .back {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
  .row {
    width: 100%;
  }
}

@media screen and (min-width: 150px) and (max-width: 760px) {
  .grid2, .grid3, .grid {
display: flex;  
flex-direction: column;
}

.flexSB {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.row {
  width: 100%;
}

.padding {
    padding: 0px 0;
}

#heading1 h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
  margin-top: 710px;
}

.margin{
  margin-top: 600px;
}

}



/*------------Updates for Opportunities-----------*/

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: rgb(140 139 139);
  font-size: 0.9rem;
}
.orangeText {
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}


/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
/* 
  .adjusted{
    display: flex;
    width: max-content;
  } */
}