.file_Not_Found{

    top: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #1eb2a6;
    color: white;
}