.newletter {
  background-color: #1eb2a6;
  padding: 50px 0;
  color: #fff;
}
.newletter .right,
.newletter .left {
  padding-right: 50px;
}
.newletter h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  margin: 15px 0 15px 0px;
}

.newletter span{
  /* margin: 15px ; */
}

.newletter .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact__form{
  width: 500px;
}

.email_here{
  min-width: 400px;
}

.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
}
.newletter i {
  padding: 12px;
  color: #1eb2a6;
  background-color: #fff;
}

#del{
  background-color: transparent;
  color: #fff;
}

footer {
  background-color: #eeeeee;
}
footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 30px;
}

.newletter .right, .newletter .left {
  padding: 20px;
}




@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }

}
footer .logo span {
  color: #1eb2a6;
  font-size: 14px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: #1eb2a6;
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: #1eb2a6;
  margin-right: 5px;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: #1eb2a6;
  margin-right: 5px;
  text-transform: capitalize;
}

.linkedin{
  font-size: 12px;
  /* color: #1eb2a6 ; */
  margin-right: 5px;
  text-transform: capitalize;
}

.social_media{
  display: flex;
  grid-template-rows: (3fr,0.25%);
  /* row-gap: 15px; */
  /* justify-content: center; */
  
}

footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: #1eb2a6;
}
.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
  background-color: #e9e9e9;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: #1eb2a6;
}

.delivered{
 display: flex;
 flex-direction: row;
 justify-content: center;
 align-items: center;
 text-align: center;
 width: 500px;
  /* align-items:center; */
  /* text-align:center; */

}

@media screen and (min-width: 150px) and (max-width: 420px)  {
  footer .container {
    grid:none;
    
  }

  .footer_advicer{
    font-size: 0.9rem;
  }

  .set_group{
    display: grid;
    grid-template-columns: 3fr 2fr;
    margin-top: 30px;
    align-items: center;
  }

  .social_media{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .legal >p{
    margin: 0 20px;
    font-size: 0.8rem;
  }

  .box{
    line-height: 0.7rem;
    font-size: 0.7rem;
  }
  .title_question{
    font-size: 1.5rem;
  }
  
  footer .last ul li{
    margin-bottom: 20px;
  }

.newletter i{
  color: #1eb2a6;
  }

  .email_here{
    min-width: 130px;
  }

  .newletter span {
    font-size: 15px;
}

  .newletter h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin: 15px 0 15px 0px;
}

.newletter .right{
  display: flex;
  justify-content: center;
  width: 100%;
}

.contact__form {
  width: 100%;
}

.newletter input {
  width: 100%;
  padding: 20px;
  border: none;
  outline: none;
  height: 30px;
  /* margin-top: 30px; */
}

footer{
  padding-top: 40px;
}

  }

